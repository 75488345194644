<template>
  <div class="management">
    <div class="container">
      <h2 class="management__title">
        Content Management
      </h2>
      <div class="management__description description">
        App Customization. Content management from ONE place.
      </div>
      <div class="management-slider">
        <swiper
          ref="swiperManagement"
          :options="swiperOptionManagement"
        >
          <swiper-slide
            v-for="item in management"
            :key="item.id"
            class="management-slider-item"
          >
            <template v-if="item.content">
              <div class="management-content">
                <div class="management__left">
                  <div class="management-content__title">
                    {{ item.title }}
                  </div>
                  <div class="management-content__desc">
                    {{ item.desc }}
                  </div>
                  <div class="management-info">
                    <button class="btn management__btn">
                      {{ item.buttonFirstText }}
                    </button>
                    <button class="btn management__btn">
                      {{ item.buttonSecondText }}
                    </button>
                  </div>
                </div>
                <div class="management__right">
                  <div
                    class="management__img"
                    :class="item.classNameImage"
                  >
                    <img
                      :src="require('../assets/images/' + item.image + '.png')"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="management__img"
                :class="item.classNameImage"
              >
                <img
                  :src="require('../assets/images/' + item.image + '.png')"
                  alt=""
                >
              </div>
            </template>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"
          ></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Management',
  data() {
    return {
      swiperOptionManagement: {
        slidesPerView: 1,
        // spaceBetween: 50,
        // delay: 5000,
        effect: 'slide',
        speed: 300,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
          bulletElement: 'span',
        },
        // navigation: {
        //   nextEl: '.swiper-arrow__right',
        //   prevEl: '.swiper-arrow__left'
        // }
      },
      management: [
        {
          id: 0,
          image: 'management__img-1',
          content: false,
          classNameImage: 'management__img_first',
        },
        {
          id: 1,
          image: 'management__img-2',
          title: 'Sermon Notes',
          desc: 'Increase Giving, Inspire Stewardship, Build the Future Increase giving and tithing – members can give anytime, anywhere',
          buttonFirstText: 'Sermon Notes for Website',
          buttonSecondText: 'How easy to add Sermon Notes',
          classNameImage: 'management__img_second',
          content: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .swiper-wrapper {
    display: flex;
      /*justify-content: center !important;*/
  }

  /*.swiper-widget-pagination {*/
  /*  margin: 44px auto 0;*/
  /*  text-align: center;*/

  /*  */
  /*}*/

  .swiper-pagination-bullets {
    bottom: 0 !important;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: $color-alto;
      border: none;
      transition: all .15s ease-in;
      opacity: 1;
      margin: 0 10px !important;
      position: relative;

      &-active {
        width: 16px;
        height: 16px;
        top: 4px;
      }
    }
  }

  .management {
    padding-bottom: 47px;
    padding-top: 71px;
    position: relative;
    overflow: hidden;
    background: $color-white;
    background-size: cover;

    &-info {
      display: flex;
      margin-top: 22px;

      .management__btn + .management__btn {
        margin-left: 16px;
      }
    }

    &__img {
      width: 1260px;
      position: relative;
      line-height: 0;

      &_second {
        pointer-events: none;
        left: -94px;
        top: -3px;
      }
    }

    &__btn {
      color: $color-royal-blue;
      width: auto;
      padding: 0 15px;
      border-radius: 5px;
      border-color: $color-moody-blue;
      transition: all .15s ease-in;

      &:hover {
        background: $color-moody-blue;
        color: $color-white;
      }
    }

    &-content {
      display: flex;

      &__title {
        font-size: 31px;
        line-height: 40px;
        font-family: $font-global-bold, sans-serif;
      }

      &__desc {
        font-size: 18px;
        line-height: 30px;
        max-width: 428px;
        margin-top: 24px;
      }
    }

    &__left {
      width: 40%;
      margin-top: 170px;
    }

    &__right {
      width: 60%;
    }

    &-slider {
      display: flex;
      height: 667px;

      &-item {
        &:first-child {
          overflow: hidden;
        }
      }
      /*overflow: hidden;*/
    }

    &__title {
      color: $color-shark;
      font-size: 37px;
      line-height: 48px;
    }

    &__description {
      color: $color-shark;
      margin-top: 9px;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 39px;
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
