<template>
  <div class="news-blog">
    <div class="container">
      <div class="news-blog-content">
        <div class="news-blog-row">
          <div class="news-blog-item">
            <div class="news-blog-item__title">
              Blog
            </div>
            <div class="news-blog-item__desc">
              <span>Begin blogs that boost</span>
              <span>passion and zeal, that</span>
              <span>connect, uplift, and inspire!</span>
              <span>Post quickly, easily.</span>
              <span>Users will be able to seamlessly navigate and share.</span>
            </div>
          </div>
          <div class="news-blog-item news-blog-item_second">
            <div class="news-blog-item__title">
              Reading Plan
            </div>
            <div class="news-blog-item__desc">
              <span>Increase Giving, Inspire</span>
              <span>Stewardship, Build the</span>
              <span>Future Increase giving</span>
            </div>
          </div>
        </div>
        <div class="news-blog-row">
          <div class="news-blog-item">
            <div class="news-blog-item__title">
              News
            </div>
            <div class="news-blog-item__desc">
              <span>Share the latest</span>
              <span>Church news, stay</span>
              <span>relevant, keep all</span>
              <span>informed.</span>
            </div>
          </div>
          <div class="news-blog-item news-blog-item_fourth">
            <div class="news-blog-item__title">
              Devotionals
            </div>
            <div class="news-blog-item__desc">
              <span>Spend Time with God – Anywhere!</span>
              <span>Devotions for small groups and</span>
              <span>individual use. Select range of</span>
              <span>devotions available.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsBlog',
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .news-blog {
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/news-blog__bg.png) no-repeat center center;
    background-size: cover;

    .container {
      /*padding-top: 81px;*/
      /*padding-bottom: 61px;*/
      padding-top: 40px;
      padding-bottom: 29px;
      background: url(../assets/images/news-blog__bg_second.png) no-repeat center center;
      background-size: cover;
    }

    &-content {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0 -60px 0 -16px;

      & + .news-blog-row {
        margin-top: 367px;
      }
    }

    &-item {
      max-width: 230px;
      width: 100%;

      &_second {
        margin-top: -8px;

        /*.news-blog-item__desc {*/
        /*  opacity: .3;*/
        /*}*/
      }

      &_fourth {
        margin-top: -32px;
        margin-right: 48px;

        .news-blog-item__desc {
          margin-top: 9px;
        }
      }

      &__title {
        font-size: 31px;
        line-height: 40px;
        color: $color-white;
        text-align: left;
        font-family: $font-global-bold, sans-serif;
      }

      &__desc {
        color: $color-white;
        font-size: 16px;
        line-height: 24px;
        margin-top: 17px;
        text-align: left;

        span {
          display: block;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
