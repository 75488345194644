<template>
  <div>
    <transition
      appear
      name="fade-slide"
    >
      <header-block-mobile />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <next-generation-general />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <innovative />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <design-mobile />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <modules />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <sermons />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <events />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <giving />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <news-blog />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <bible />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <live-streaming />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <ministries />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <news-feed />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <staff />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <management />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <best-practices-mobile />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <feedback-mobile />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <bundles-mobile />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <integrations />
    </transition>
    <template v-if="$route.name === 'mobilePage'">
      <transition
        appear
        name="fade-slide"
      >
        <questions-mobile />
      </transition>
    </template>
    <template v-else>
      <transition
        appear
        name="fade-slide"
      >
        <hubspotForm3
          :appendForm="true"
        />
      </transition>
      <!--      <amoBtn />-->
    </template>
  </div>
</template>

<script>
import headerBlockMobile from '@/components/headerBlockMobile';
import designMobile from '@/components/designMobile';
import modules from '@/components/modules';
import sermons from '@/components/sermons';
import events from '@/components/events';
import giving from '@/components/giving';
import newsBlog from '@/components/news-blog';
import bible from '@/components/bible';
import liveStreaming from '@/components/liveStreaming';
import ministries from '@/components/ministries';
import newsFeed from '@/components/newsFeed';
import staff from '@/components/staff';
import management from '@/components/management';
import bestPracticesMobile from '@/components/bestPracticesMobile';
import feedbackMobile from '@/components/feedbackMobile';
import bundlesMobile from '@/components/bundlesMobile';
import integrations from '@/components/integrations';
import questionsMobile from '@/components/questionsMobile';
import innovative from '@/components/innovative';
import nextGenerationGeneral from '@/components/nextGenerationGeneral';
import hubspotForm3 from '@/components/hubspotForm3';
// import amoBtn from '@/components/amoBtn';

export default {
  name: 'MobilePage',
  components: {
    headerBlockMobile,
    designMobile,
    modules,
    sermons,
    events,
    giving,
    newsBlog,
    bible,
    liveStreaming,
    ministries,
    newsFeed,
    staff,
    management,
    bestPracticesMobile,
    feedbackMobile,
    bundlesMobile,
    integrations,
    questionsMobile,
    innovative,
    nextGenerationGeneral,
    hubspotForm3,
    // amoBtn,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  beforeMount() {
    document.querySelector('body').classList.add('body_mobile');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('body_mobile');
  },
  methods: {
  },
};
</script>

<style lang="scss">
  .body_mobile {
    min-width: 1300px;
  }
</style>
