<template>
  <div class="ministries">
    <div class="container">
      <div class="ministries-content">
        <div class="ministries-row">
          <div class="ministries-item">
            <div class="ministries-item__title">
              Ministries
            </div>
            <div class="ministries-item__desc">
              <span>Share the Heart of</span>
              <span>Ministries.</span>
              <span>Increase participation</span>
              <span>as everyone stays</span>
              <span>informed.</span>
              <span>Contact or register/</span>
              <span>sign-up in a few</span>
              <span>clicks!</span>
            </div>
          </div>
          <div class="ministries-item ministries-item_second">
            <div class="ministries-item__title">
              Testimonies
            </div>
            <div class="ministries-item__desc">
              <span>Don’t miss this one! When</span>
              <span>people share their “God</span>
              <span>stories” of how He has worked</span>
              <span>in their lives, hearts are</span>
              <span>changed for eternity. Users can</span>
              <span>view and record video, audio or</span>
              <span>text testimonials straight from</span>
              <span>your app. Ideal for mission</span>
              <span>updates and outreach events.</span>
            </div>
          </div>
        </div>
        <div class="ministries-row">
          <div class="ministries-item">
            <div class="ministries-item__title">
              Photos
            </div>
            <div class="ministries-item__desc">
              <span>Post Your Favorites!</span>
              <span>Mission groups can post</span>
              <span>videos and pictures instantly!</span>
              <span>Youth groups and others can</span>
              <span>create videos – and share.</span>
            </div>
          </div>
          <div class="ministries-item ministries-item_fourth">
            <div class="ministries-item__title">
              About
            </div>
            <div class="ministries-item__desc">
              <span>Introduce your Church,</span>
              <span>mission, vision, and pastor.</span>
              <span>Helps everyone feel inspired,</span>
              <span>connected, and “one in Spirit.”</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ministries',
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .ministries {
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/ministries__bg.png) no-repeat center center;
    background-size: cover;

    .container {
      /*padding-top: 46px;*/
      /*padding-bottom: 28px;*/
      padding-top: 26px;
      padding-bottom: 8px;
      background: url(../assets/images/ministries__bg_second.png) no-repeat;
      background-position: -55% 30%;
    }

    &-content {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0 -60px 0 -16px;

      & + .ministries-row {
        margin-top: 135px;
      }
    }

    &-item {
      max-width: 230px;
      width: 100%;

      &_second {
        margin-top: 106px;
        margin-right: 28px;
      }

      &_fourth {
        margin-top: 90px;
        padding-left: 25px;

        .ministries-item__desc {
          margin-top: 17px;
        }
      }

      &__title {
        font-size: 31px;
        line-height: 40px;
        color: $color-white;
        text-align: left;
        font-family: $font-global-bold, sans-serif;
      }

      &__desc {
        color: $color-white;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
        text-align: left;

        span {
          display: block;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
