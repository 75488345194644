<template>
  <div
    id="slideNumberTwo"
    class="design-mobile"
  >
    <div class="container">
      <h2 class="design-mobile__title">
        Design
      </h2>
      <div class="design-mobile__description description">
        Your ministry is unique, your app should be too
      </div>
      <div class="design-mobile-list">
        <div class="design-mobile-row">
          <div
            v-for="(item) in imageMobile"
            v-if="item.id < 4"
            :key="item.id"
            class="design-mobile__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
        <div class="design-mobile-row">
          <div
            v-for="(item) in imageMobile"
            v-if="item.id > 3"
            :key="item.id"
            class="design-mobile__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
      </div>
      <a
        v-if="false"
        v-scroll-to="'#slideNumberTwo'"
        href=""
        class="design-mobile__arrow"
        @click="showArrowScroll = false"
      >
        <i class="icon-arrow"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignMobile',
  components: {
  },
  data() {
    return {
      showArrowScroll: true,
      imageMobile: [
        {
          id: 0,
          img: 'design-mobile__img-1',
        },
        {
          id: 1,
          img: 'design-mobile__img-2',
        },
        {
          id: 2,
          img: 'design-mobile__img-3',
        },
        {
          id: 3,
          img: 'design-mobile__img-4',
        },
        {
          id: 4,
          img: 'design-mobile__img-5',
        },
        {
          id: 5,
          img: 'design-mobile__img-6',
        },
        {
          id: 6,
          img: 'design-mobile__img-7',
        },
        {
          id: 7,
          img: 'design-mobile__img-8',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .design-mobile {
    padding-bottom: 95px;
    padding-top: 80px;
    position: relative;
    background: $color-white url(../assets/images/design__bg.png) no-repeat center center;
    background-size: cover;

    .container {
      position: relative;
    }

    &__arrow {
      color: $color-white;
      position: absolute;
      top: -106px;
      left: -4px;
      background: $color-moody-blue;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 16px;
      cursor: pointer;
      /*box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);*/
      opacity: 1;
      z-index: 900;
      transition: opacity .2s ease;

      /*&_top {*/
      /*  top: 20px;*/
      /*}*/

      &:hover {
        opacity: .6;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin: 80px -38px 30px;
    }

    &-row {
      display: flex;
    }

    &__img {
      margin: 6px 8px;
    }

    &__title {
      color: $color-shark;
    }

    &__description {
      color: $color-jumbo;
      margin-top: 9px;

      span {
        display: block;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
