<template>
  <div class="events">
    <div class="container">
      <div class="events-content">
        <h2 class="events__title">
          Events, Registrations & Tickets
        </h2>
        <div class="events__description description">
          Increase Giving, Inspire Stewardship, Build the
          Future Increase giving and tithing. Members can give anytime, anywhere.
        </div>
        <div class="events-list">
          <div
            v-for="(item) in imageEvents"
            :key="item.id"
            class="events__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Events',
  data() {
    return {
      imageEvents: [
        {
          id: 0,
          img: 'events__img-1',
        },
        {
          id: 1,
          img: 'events__img-2',
        },
        {
          id: 2,
          img: 'events__img-3',
        },
        {
          id: 3,
          img: 'events__img-4',
        },
        {
          id: 4,
          img: 'events__img-5',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .events {
    /*padding-bottom: 48px;*/
    /*padding-top: 80px;*/
    padding-bottom: 75px;
    padding-top: 107px;
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/events__bg.png) no-repeat center center;
    background-size: cover;

    &-content {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
    }

    &-list {
      display: flex;
      margin: 43px -18px 0;
    }

    &__img {
      margin: 0 -8px;
    }

    &__title {
      font-size: 31px;
      line-height: 40px;
      color: $color-white;
      text-align: left;
      margin-left: -15px;
    }

    &__description {
      color: $color-white;
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
      text-align: left;
      max-width: 433px;
      width: 100%;
      margin-left: -15px;
    }
  }

  @media (max-width: 1199px) {
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
  }
</style>
