<template>
  <div class="news-feed">
    <!--    <div class="video">-->
    <!--      <template v-if="pcMode">-->
    <!--        <video loop muted autoplay playsinline class="news-feed__video">-->
    <!--          <source src="../assets/video/news-feed.mp4" type="video/mp4">-->
    <!--        </video>-->
    <!--      </template>-->
    <!--      <template v-else>-->
    <!--        <video loop muted autoplay playsinline class="news-feed__video">-->
    <!--          <source src="../assets/video/news-feed-mob.mp4" type="video/mp4">-->
    <!--        </video>-->
    <!--      </template>-->
    <!--    </div>-->
    <div class="container">
      <div class="news-feed-content">
        <h2 class="news-feed__title">
          Newsfeed
        </h2>
        <div class="news-feed__description description">
          This module is like a daily digest (briefing) for users, it aggregates
          all the latest apps’ content and activity from all modules and presents
          it in one place. Users can set up reminders and read daily updates
          in a convenient way.
        </div>
        <div class="news-feed__label">
          *Custom design
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MobileDetect from 'mobile-detect'
export default {
  name: 'NewsFeed',
  data() {
    return {
      // pcMode: true
    };
  },
  mounted() {
    // let md = new MobileDetect(window.navigator.userAgent)
    // let mobile = md.mobile()
    // let tablet = md.tablet()
    // if (mobile !== null || tablet !== null) {
    //   console.log('ADAPTIVE')
    //   this.pcMode = false
    // } else {
    //   console.log('DESCTOP')
    // }
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';

  .news-feed {
    padding-bottom: 565px;
    padding-top: 225px;
    position: relative;
    overflow: hidden;
    background: url(../assets/images/news-feed__bg.png) no-repeat center center;
    //background: $color-white;
    background-size: cover;

    .video {
      /*position: absolute;*/
      /*!*padding-bottom: 46.25%;*!*/
      /*overflow: hidden;*/
      /*height: 863px;*/
      /*top: 0;*/
      /*left: 0;*/
      /*width: 100%;*/
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
    }

    &__video {
      /*position: absolute;*/
      /*width: 100%;*/
      /*display: block;*/
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      min-width: 50%;
      min-height: 50%;
    }

    &-content {
      position: absolute;
      left: 48.5%;
      top: 102px;
      transform: translateX(-50%);
      max-width: 347px;
      width: 100%;
      margin-left: 103px;
    }

    &__title {
      color: $color-white;
      font-size: 31px;
      line-height: 40px;
      text-align: left;
    }

    &__description {
      color: $color-white;
      margin-top: 24px;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
    }

    &__label {
      font-family: $font-global, sans-serif;
      font-size: 12px;
      line-height: 30px;
      color: $color-gunsmoke;
      text-align: left;
      margin-top: 16px;
      margin-left: 2px;
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
