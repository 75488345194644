<template>
  <div class="staff">
    <div class="container">
      <div class="staff-list">
        <div
          v-for="(item) in imageStaff"
          :key="item.id"
          class="staff-item"
          :class="[
            item.className
          ]"
        >
          <div class="staff-item__title">
            {{ item.title }}
          </div>
          <div
            class="staff-item__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
            <span
              v-if="item.className === 'staff-item_staff'"
              class="staff-item__text"
            >Coming Soon</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Staff',
  data() {
    return {
      imageStaff: [
        {
          id: 0,
          title: 'Staff',
          img: 'staff__img-1',
          className: 'staff-item_staff',
        },
        {
          id: 1,
          title: 'User Profile',
          img: 'staff__img-2',
          className: 'staff-item_user-profile',
        },
        {
          id: 2,
          title: 'Settings',
          img: 'staff__img-3',
          className: 'staff-item_user-settings',
        },
        {
          id: 3,
          title: 'Links',
          img: 'staff__img-4',
          className: 'staff-item_user-links',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .staff {
    padding-top: 56px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    background: $color-mine-shaft url(../assets/images/staff__bg.png) no-repeat center center;
    background-size: cover;

    &-list {
      display: flex;
      margin: 0px -113px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 7px;

      &__text {
        position: absolute;
        top: 49%;
        left: 53%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: $color-gray;
        font-family: $font-global-medium, sans-serif;
      }

      &__img {
        line-height: 0;
      }

      &_staff {
        margin-top: 50px;

        .staff-item__img {
          position: relative;
        }
      }

      &_user-profile {
        margin-top: 199px;
      }

      &_user-links {
        margin-top: 95px;
      }

      &__title {
        color: $color-white;
        font-size: 31px;
        line-height: 40px;
        margin-bottom: 24px;
        font-family: $font-global-bold, sans-serif;
        padding-left: 20px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
