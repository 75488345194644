<template>
  <div class="best-practices-mobile-elements">
    <div
      v-for="(item, index) in bestPracticesElement"
      :key="index"
      class="best-practices-mobile-elements-item"
    >
      <div class="best-practices-mobile-elements-item__title">
        {{ item.title }}
      </div>
      <div
        class="best-practices-mobile-elements-item__img "
        :class="item.classItemName"
      >
        <img
          :src="require('../assets/images/' + item.img + '.svg')"
        >
      </div>
      <div class="best-practices-mobile-elements-item__info">
        <button
          v-if="item.btn"
          class="best-practices-mobile-elements-item__btn"
        >
          {{ item.btn }}
        </button>
        <div
          v-if="item.coming"
          class="best-practices-mobile-elements-item__text"
        >
          Coming Soon
        </div>
        <span
          v-if="item.text !== undefined"
          class="best-practices-mobile-elements-item__text"
        >
          {{ item.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BestPracticesMobileElements',
  data() {
    return {
      bestPracticesElement: [
        {
          title: 'Built for Performance & Growth',
          img: 'Built',
          // btn: 'Learn more',
          classItemName: 'best-practices-mobile-elements-item__img_built',
        },
        {
          title: 'Push Notifications',
          img: 'push-notific',
          coming: true,
          classItemName: 'best-practices-mobile-elements-item__img_notific',
        },
        {
          title: 'App Stores Optimization',
          img: 'SEO',
          classItemName: 'best-practices-mobile-elements-item__img_seo',
        },
        {
          title: 'Smart App Banners & App Promo Page',
          img: 'Smart',
          // btn: 'Learn more',
          classItemName: 'best-practices-mobile-elements-item__img_smart',
        },
        {
          title: 'Announcement materials',
          img: 'materials',
          // btn: 'Learn more',
          classItemName: 'best-practices-mobile-elements-item__img_materials',
        },
        {
          title: 'Integrated Giving',
          img: 'giving',
          // btn: 'Learn more',
          classItemName: 'best-practices-mobile-elements-item__img_giving',
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null,
        },
      );
      wow.init();
    });
  },
  methods: {},
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .best-practices-mobile {

    &-elements {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -30px;
      padding-top: 48px;

      &-item {
        max-width: 360px;
        width: 100%;
        height: 360px;
        background: $color-white;
        padding: 24px;
        box-shadow: 0 8px 16px rgba(48, 49, 51, .2);
        border-radius: 6px;
        margin: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all .25s ease-in;
        position: relative;
        box-sizing: border-box;

        &:hover, &:focus {
          transform: scale(1.05);
          box-shadow: 5px 16px 32px rgba(48, 49, 51, .2);
        }

        &__title {
          font-family: $font-global-bold, sans-serif;
          font-size: 21px;
          line-height: 31px;
          text-align: center;
          margin-bottom: 25px;
          position: relative;
          z-index: 1;
        }

        &__img {
          position: relative;
          max-width: 180px;
          align-items: center;
          display: flex;
          flex-grow: 1;
          height: 100%;
          overflow: hidden;

          &_materials {
            margin-top: 25px;
          }

          &_built {
            top: 4px;
          }

          &_notific {
            max-width: 250px;
            top: 9px;
          }

          &_seo {
            top: 26px;
          }

          &_smart {
            top: -10px;
            left: -4px;
          }

          &_materials {
            max-width: 230px;
          }

          &_giving {
            top: 9px;
          }
        }

        &__info {
          height: 34px;
          flex-shrink: 0;
          margin-top: 25px;
          display: flex;
          align-items: center;
        }

        &__text {
          font-family: $font-global-medium, sans-serif;
          font-size: 14px;
          color: $color-jumbo;
        }

        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 101px;
          height: 34px;
          border: 1px solid $color-french-rose;
          border-radius: 30px;
          color: $color-crimson;
          font-family: $font-global-medium;
          font-size: 14px;
          margin: auto;
          position: relative;
          transition: all .15s ease;
          overflow: hidden;
          cursor: pointer;

          &:hover, &:focus {
            background: $color-crimson;
            color: $color-white;
            border-color: $color-crimson;
          }

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, .5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
          }

          @keyframes ripple {
            0% {
              transform: scale(0, 0);
              opacity: 1;
            }
            20% {
              transform: scale(25, 25);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(40, 40);
            }
          }

          &:focus:not(:active)::after {
            animation: ripple 2s ease-out;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
