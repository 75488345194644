<template>
  <div class="giving">
    <div class="container">
      <div class="giving-content">
        <h2 class="giving__title">
          2-Clicks™ Mobile Giving
        </h2>
        <div class="giving__description description">
          Increase Giving, Inspire Stewardship, Build the Future Increase
          giving and tithing – members can give anytime, anywhere.<br>
          Fast, convenient, and secure with 3 levels of authorization.
          You’ll help ignite joy and passion for His kingdom as you offer
          this exclusive benefit: actual mobile giving, the first of its
          kind world-wide. It’s changing the way people give in churches today!
        </div>
        <div class="giving-info">
          <button class="btn giving__btn">
            Explore Giving Platform
          </button>
        </div>
        <div class="giving-list">
          <div
            v-for="(item) in imageGiving"
            :key="item.id"
            class="giving__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Giving',
  data() {
    return {
      imageGiving: [
        {
          id: 0,
          img: 'giving__img-1',
        },
        {
          id: 1,
          img: 'giving__img-2',
        },
        {
          id: 2,
          img: 'giving__img-3',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .giving {
    /*padding-bottom: 113px;*/
    /*padding-top: 90px;*/
    padding-bottom: 62px;
    padding-top: 39px;
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/giving__bg.png) no-repeat top center;
    background-size: 100%;

    &-content {
      max-width: 620px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
      padding-left: 22px;
    }

    &-list {
      display: flex;
      margin: 0 -19px 77px;
      order: -1;
    }

    &__img {
      margin: 0 -5px;
    }

    &__title {
      font-size: 31px;
      line-height: 40px;
      color: $color-shark;
      text-align: left;
    }

    &__description {
      color: $color-shark;
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
      text-align: left;
      max-width: 484px;
      width: 100%;
    }

    &-info {
      display: flex;
      margin-top: 15px;
      visibility: hidden;
      opacity: 0;
    }

    &__btn {
      color: $color-curious-blue;
      border-color: $color-picton-blue;
      text-decoration: none;
      border-radius: 5px;
      width: auto;
      padding: 0 18px;
      transition: all .15s ease-in;

      &:hover {
        background: $color-picton-blue;
        color: $color-white;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
