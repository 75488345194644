<template>
  <div id="container">
    <div
      id="next-generation-mobile"
      class="next-generation-mobile"
    >
      <div class="container">
        <div class="next-generation-mobile-content">
          <div class="next-generation-mobile-left">
            <h1 class="next-generation-mobile__title">
              <span>Mobile is The Most Powerful</span>
              <span>Medium of the Century, and</span>
              <span class="next-generation-mobile__title_last"><span class="next-generation-mobile__title_fix">is the best tool to</span><span class="next-generation-mobile__title_selected">Engage</span></span>
            </h1>
          </div>
        </div>
        <div
          v-if="$route.name !== 'pricingPage'"
          class="actions"
        >
          <button
            class="btn actions__btn actions__btn_try"
            @click="showModalNewProject = true"
          >
            Try Demo
          </button>
          <button
            class="btn actions__btn actions__btn_get"
            @click="showModalGetStarted = true"
          >
            Get Started
          </button>
        </div>
        <div class="next-generation-mobile__text">
          Starting from $50/month
        </div>
        <div class="next-generation-mobile__img">
          <img
            src="../assets/images/mobile-img.png"
            alt=""
          >
        </div>
      </div>
      <div class="next-generation-mobile__panel">
        <div class="next-generation-mobile__sign">
          Custom branded app in the App Stores. Simple and transparent pricing
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Typed from 'typed.js'
import MobileDetect from 'mobile-detect';

export default {
  name: 'NextGenarationMobile',
  components: {
  },
  props: ['showModalVideo'],
  data() {
    return {
      shapeAnimation: true,
    };
  },
  computed: {
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  methods: {
    setShowModalVideo() {
      this.$emit('update:showModalVideo', true);
      setTimeout(() => {
        // console.log(document.getElementsByClassName('vjs-play-control')[0])
        // document.getElementsByClassName('vjs-play-control')[0].click()
      }, 1000);
    },
    escape(event) {
      // console.log('Esc key pressed.', `Event: ${event}`)
      // console.log('dsfsdfsdsdf')
    },
  },
  /* eslint-disable */
  mounted: function () {

    /* eslint-disable */
    // let typed = new Typed('.next-generation-mobile__title_selected', {
    //   strings: ['Engage', 'Inform', 'Communicate', 'Inspire', 'Impact', 'Fundraise', 'Educate', 'Convert'],
    //   typeSpeed: 40,
    //   backSpeed: 40,
    //   showCursor: false,
    //   // startDelay: 200,
    //   // fadeOut: 300,
    //   // backDelay: 90,
    //   loop: true
    // })

    let md = new MobileDetect(window.navigator.userAgent)
    let mobile = md.mobile()
    let tablet = md.tablet()
    if (mobile !== null || tablet !== null) {
      // document.querySelector('.scene').classList.add('scene_hide')
      document.querySelector('.next-generation-mobile').classList.add('next-generation-mobile_not-vh')
    } else {
      document.querySelector('.next-generation-mobile').classList.remove('next-generation-mobile_not-vh')
    }
    this.$nextTick(() => {
      let wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null
        }
      )
      wow.init()
      // setTimeout(() => {
      //   window.scrollTo(0, 0)
      //   console.log(window.scrollY)
      // }, 1000)
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

  .fullscreen-bg__video {
    &_mob {
      display: none;
    }
  }

  .next-generation-mobile {
    margin-top: -126px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    background: $color-black url(../assets/images/main-bg.png) no-repeat center center;
    background-size: cover;
    padding-top: 299px;

    &__panel {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(11, 10, 10, .75);
      box-shadow: 0px -1px 0px rgba(255, 255, 255, .24);
      height: 63px;
      z-index: 900;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      display: flex;
      justify-content: center;
      margin-right: 25px;
    }

    &_not-vh {
      height: 100%;
    }

    .container {
      position: relative;
      /*z-index: 800;*/
    }

    &__text {
      line-height: 40px;
      font-size: 31px;
      text-align: center;
      margin-bottom: 90px;
      color: $color-white;
    }

    &__sign {
      font-family: $font-global, sans-serif;
      line-height: 31px;
      font-size: 21px;
      color: $color-chateau-green;
      position: relative;
      top: -1px;
    }

    &__title {
      font-size: 56px;
      line-height: 64px;
      text-align: center;
      color: $color-white;
      font-family: $font-global-bold, sans-serif;

      &_fix {
        display: inline-block !important;
        margin-right: 5px;
      }

      &_last {
        text-align: left;
        margin-left: 40px;
        position: relative;
      }

      span {
        display: block;
      }

      &_selected {
        color: $color-black;
        background: $color-chartreuse;
        display: inline-block !important;
        padding: 0 8px;
        position: absolute;
        /*width: 210px;*/
      }
    }

    &__desc {
      font-family: $font-second, sans-serif;
      color: $color-dusty-gray-second;
      font-size: 20px;
      position: relative;
      top: -4px;
    }

    &-content {
      display: flex;
      justify-content: center;
      margin-bottom: 57px;
      flex-direction: column;
      align-items: center;
    }

    &-right {
      max-width: 555px;
      min-height: 383px;
      width: 100%;
      background: $color-white;
      position: relative;
      top: -11px;
      left: 15px;
      box-shadow: 16px 41px 76px $color-blizzard-blue;
      border-radius: 12px;
    }

    &-left {
      position: relative;
    }
  }

  .fade-modal {
    &-enter-active {
      transition: .2s ease-in;
      .modals-video__content {
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-leave-active {
      transition: .2s ease-in;

      .modals-video__content {
        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-enter, &-leave-to {
      opacity: 0;

      .modals-video__content {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  /*#container {*/
  /*  perspective: 300px;*/
  /*}*/

  #inner {
    transition: transform .15s;
  }
</style>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

  .actions {
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    display: none;

    &__btn {
      transition: all .14s ease;

      &_get {
        height: 40px;
        background: $color-picton-blue;
        font-family: $font-global-medium, sans-serif;
        font-size: 16px;
        display: inline-block;
        color: $color-white;
        padding: 0 6px;
        border: 1px solid transparent;
        width: 137px;
        transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
        user-select: none;

        &:hover {
          opacity: .8;
        }

        &:active {
          background: $color-martinique;
          opacity: 1;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: rgba(255, 255, 255, .5);
          opacity: 0;
          border-radius: 100%;
          transform: scale(1, 1) translate(-50%);
          transform-origin: 50% 50%;
        }

        @keyframes ripple {
          0% {
            transform: scale(0, 0);
            opacity: 1;
          }
          20% {
            transform: scale(25, 25);
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: scale(40, 40);
          }
        }

        &:focus:not(:active)::after {
          animation: ripple 1.5s ease-out;
        }
      }

      &_try {
        border-color: $color-moody-blue;
        color: $color-royal-blue;
        width: 137px;
        height: 40px;
        font-size: 16px;

        &:hover {
          color: $color-white;
          background: $color-moody-blue;
        }
      }

      &:last-child {
        margin-left: 16px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
