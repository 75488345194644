<template>
  <div class="questions-mobile">
    <div class="container">
      <h2
        class="questions-mobile__title"
      >
        Frequently Asked Questions
      </h2>
      <transition
        name="fade-block"
        mode="out-in"
        appear
      >
        <div class="faq-list">
          <div class="faq-area">
            <transition-group
              v-for="(item, index) in faq"
              :key="item.answer"
              name="fade-block"
              appear
            >
              <div
                :key="index"
                class="faq-item"
                :class="[{'faq-item_active': toggleActive === index}, {'faq-item_last': item.lastElem}]"
                @click="toggle(item, index, $event)"
              >
                <p
                  class="faq__question"
                  :class="{'faq__question_active': toggleActive === index}"
                >
                  {{ item.question }}
                </p>
                <transition
                  name="fade-el"
                  mode="out-in"
                >
                  <div
                    v-if="toggleActive === index"
                    class="faq__answer"
                    v-html="item.answer"
                  >
                  </div>
                </transition>
              </div>
            </transition-group>
          </div>
        </div>
      </transition>
      <transition
        name="fade-block"
        mode="out-in"
        appear
      >
        <div
          v-if="addFaq"
          class="faq-list"
        >
          <div class="faq-area">
            <div
              v-for="(item, index) in faqSecond"
              :key="index"
              class="faq-item"
              :class="{'faq-item_active': toggleActiveSecond === index}"
              @click="toggleSecond($event, index)"
            >
              <p
                class="faq__question"
                :class="{'faq__question_active': toggleActiveSecond === index}"
              >
                {{ item.question }}
              </p>
              <transition
                name="fade-block"
                mode="out-in"
              >
                <div
                  v-if="toggleActiveSecond === index"
                  class="faq__answer"
                  v-html="item.answer"
                >
                </div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
      <button
        class="questions-mobile__btn"
        @click.prevent="!addFaq ? addQuesstions() : removeQuesstions()"
      >
        <template v-if="!addFaq">
          Read more
        </template>
        <template v-if="addFaq">
          Read less
        </template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionsMobile',
  data() {
    return {
      addFaq: false,
      faq: [
        {
          question: 'I am busy now, but I want to take advantage of this offer.',
          answer: '<p>This offer is available for a limited time only. If you want to seize this opportunity, we highly recommend that you sign up, complete the registration process and you can publish your apps later. Once you are ready to announce and launch your apps, we will work with you on the launch.</p>',
        },
        {
          question: 'Is this one shell app that you use for everyone, or are you creating actual, native apps on a separate platform for every organization?',
          answer: '<p>No, this is not a shell app. We will create a separate, unique, native app on each platform for your organization. All apps are published separately for every client. It is our goal to provide you with the best practical solution that will help your organization grow and provide an exceptional experience for your users.</p>',
        },
        {
          question: 'When will my apps be published?',
          answer: '<p>The average turnaround time is about 1 month. Getting your apps live will also depend on your responsiveness and how fast you provide your feedback and follow our guidelines to complete all steps. If you have a specific request, please <a href="mailto:sales@impactfactors.net">contact us</a> directly and we will gladly accommodate those for you.</p>',
        },
        {
          question: 'How will my apps will be published (together or separate)?',
          answer: '<p>We recommend publishing your apps in two phases: first we will publish the mobile app and work with you on announcing it to your congregation, using the best practices. After that, we will work with you on announcing your TV apps. Usually we publish mobile and TV apps at relatively the same time, but we still highly recommend announcing them to your congregation in two phases to ensure excitement and high penetration.</p>',
        },
        {
          question: 'Is there a contract?',
          answer: '<p>For this offer, we require a 2-year contract. With this offer, we have a lot of upfront expenses as we do a lot of custom work for you. 99.9% of our clients stay with us even after their contract expires. To opt-out, you will need to <a href="mailto:sales@impactfactors.net">contact us</a> 30 days prior to your contract expiration date. We assure you that you won’t find a better deal or better technology solution anywhere else. This is just the beginning and we are only getting started.</p>',
        },
        {
          question: 'How can I pay for this service?',
          answer: '<p>We accept all major credit and debit cards, PayPal, and Amazon payments. You will be able to update your billing information any time in your admin panel. Also, you will be able to download receipts, see reports, etc. Later, we will be accepting ACH/checks and invoice payments. Your payments will be PCI-DSS Level 1 compliant, the highest level of security a business can offer. Cardholder data is sent directly to our processor that meets and exceeds all industry-standard payment security practices to protect you and your customers.</p>',
        },
        {
          question: 'Do I need to get a developer certificate to be able to publish my apps?',
          answer: '<p>Yes, we can publish your apps under your personal or your organizations’ developer certificate. We will help you to get your certificates. You can apply for certificates simultaneously while getting your apps ready. If you are a registered nonprofit, Apple will waive their $99/year fee, but you will need to pay $25 one-time fee to get your Google Developer Certificate. We will provide manuals for you and will help you in case of any questions during that process. Usually it’s simple and fast!</p>',
        },
        {
          question: 'Can I publish my apps under my existing developer certificate?',
          answer: '<p>Yes, this is included in the pricing. If you don’t have a developer certificate, we will help you to obtain one. If you are a registered nonprofit, you can get a waiver for Apple’s $99/year fee and get your Developer Certificate for free. You will need to pay $25 one-time fee for Google Play to be able to publish your Android App.</p>',
          lastElem: true,
        },
      ],
      faqSecond: [
        {
          question: 'I already have an app and I want to switch to your apps. Can you help?',
          answer: '<p>Yes, if you want to do that, please <a href="mailto:sales@impactfactors.net">contact us</a> directly. We can help to transfer your app from another provider to us or to your own certificate if necessary.  Please have all this information ready before contacting us.</p>',
        },
        {
          question: 'I would like to help you sell your solution.',
          answer: '<p>If you think you can help us promote our offer to many other churches or ministries, please <a href="mailto:sales@impactfactors.net">contact us</a> to discuss how we can partner with you on that.</p>',
        },
        {
          question: 'How will I be able to manage my content for my mobile and TV apps?',
          answer: '<p>Once you complete registration and follow a few steps in our wizard, we will create your portal (CMS) where you will be able to manage all your content any time, see statistics, and more. We are continuously improving our CMS to make sure you have the best tools, a great user experience, and the most efficient processes.</p>',
        },
        {
          question: 'How will the design process work?',
          answer: '<p>There are three options to design your app: </p> <ol><li>We can help you with our template-based designs (no charge, we’ll do it as a bonus for you ;)</li><li>You can order custom design ($250 one-time fee) and we will get in touch with you and will work with you on your custom icon and splash screen design. If you have more specific requests, please <a href="mailto:sales@impactfactors.net">contact us</a>.</li><li>You can create design by using our customization interface and our templates. </li></ol><p>After app is live, you’ll be able to make any changes and publish them live.</p>',
        },
        {
          question: 'Can we use our own designer for our apps?',
          answer: '<p>Yes, please <a href="mailto:sales@impactfactors.net">contact us</a>. We will provide you with specifications for your designer. This will be a simple guideline that your designer will use when creating the design. We will need your designer to follow our specification so that we can use his work for your new apps.</p>',
        },
        {
          question: 'We don’t have logo yet, can you help?',
          answer: '<p>In this case, there are two options for you:</p> <p>1) Here is a list of suggested vendors that can create a nice logo for you;</p><p>2) We can simply use your organization’s name as your logo.</p>',
        },
        {
          question: 'Can we use our own giving solution?',
          answer: '<p>Yes, just simply link your giving icon to any other thirty-party link.</p>',
        },
        {
          question: 'What about live-streaming? How can we use our live-streaming provider with your apps?',
          answer: '<p>There are two options for you in this case:</p><ul><li>If you want to use our native live-streaming module, you will need to add in CMS your streaming link in .m3u8 or .rtsp format. Your live-streaming provider will be able to provide you with the streaming link in this format. This way you will get best, native live-streaming experience on mobile and TV platforms.</li><li>If you don’t have a native streaming link, just set up an external URL that will lead to your live streaming channel. This option will work as an in-app browser experience on a mobile app, but will not be available on TV apps. This is why we recommend using streaming links for native functionality. If you need help with a live-streaming provider, please <a href="mailto:sales@impactfactors.net">contact us</a> and we will gladly help you get the best option for a native live-streaming experience.</li></ul>',
        },
        {
          question: 'Can we use integration with our Church Management Software?',
          answer: '<p>We are currently integrated with the following church management systems:</p><ul class="list-circle"><li>Church Community Builder</li><li>ACS Technologies</li></ul><p>Church Management Software is an additional paid module with a small monthly fee. For now, we integrated with the main modules, such as User Login & Registration and Giving.</p>',
        },
        {
          question: 'Do you support RSS/JSON/ATOM feeds for content modules?',
          answer: '<p>Yes.</p>',
        },
        {
          question: 'I have more questions; how can I get in touch with your company?',
          answer: '<p>For any other questions, please <a href="mailto:sales@impactfactors.net">contact us</a></p>',
        },
      ],
      toggleActive: false,
      toggleActiveSecond: false,
    };
  },
  methods: {
    toggle(item, index, e) {
      if (e.target.tagName === 'A' || e.target.tagName === 'a') {
        this.toggleActive = true;
      }
      if (this.toggleActive === index) {
        this.toggleActive = false;
      } else {
        this.toggleActive = index;
      }

      if (item.lastElem) {
        item.lastElem = false;
        this.addFaq = true;
      }
    },
    toggleSecond(e, index) {
      if (e.target.tagName === 'A' || e.target.tagName === 'a') {
        this.toggleActiveSecond = true;
      }
      if (this.toggleActiveSecond === index) {
        this.toggleActiveSecond = false;
      } else {
        this.toggleActiveSecond = index;
      }
    },
    addQuesstions() {
      this.addFaq = !this.addFaq;
      this.faq[7].lastElem = false;
    },
    removeQuesstions() {
      this.addFaq = !this.addFaq;
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

 .questions-mobile {
    background: $color-white;
    padding-top: 56px;
    padding-bottom: 83px;
    position: relative;

   &__title {
     margin-bottom: 43px;
   }

   .list-circle {
     list-style-type: circle;
   }

   &__btn {
     display: flex;
     justify-content: center;
     width: 100%;
     color: $color-royal-blue-second;
     font-size: 18px;
     cursor: pointer;
     margin-top: 30px;
     letter-spacing: -.6px;
     position: relative;
     left: -1px;
     transition: opacity .3s ease ;

     &:hover {
       opacity: .6;
     }
   }
 }

 .faq {

   &-list {
     margin: 0 -15px;
   }

   &-item {
     box-shadow: 0 1px 3px rgba(48, 49, 51, .2);
     border-radius: 3px;
     margin-bottom: 8px;
     cursor: pointer;
     padding-left: 24px;
     padding-right: 100px;
     border: 1px solid transparent;
     box-sizing: border-box;
     transition: border-color .3s ease, height .3s ease, min-height .3s ease, transform .25s ease-in, box-shadow .25s ease-in;

     &:hover {
       transform: translateY(-4px);
       box-shadow: 2px 3px 10px rgba(48, 49, 51, .2);
     }

     &_last {
       opacity: .2;
       box-shadow: none;
     }

     &_def {
       opacity: 1;
       box-shadow: 0 1px 3px rgba(48, 49, 51, .2);
     }

     &_active {
       border-color: $color-royal-blue-second;
     }
   }

   &__question {
     display: flex;
     align-items: center;
     min-height: 60px;
     width: 100%;
     max-width: 100%;
     background: $color-white;
     font-family: $font-global-bold, sans-serif;
     font-size: 18px;
     line-height: 30px;
     position: relative;

     &:before {
       content: "\E804";
       font-family: $icon-font;
       position: absolute;
       right: -81px;
       top: 18px;
       color: $color-moody-blue;
       font-size: 12px;
       transition: transform .15s ease;
       transform: rotate(90deg);
     }

     &_active {
       &:before {
         transform: rotate(0);
       }
     }
   }

   &__answer {
     display: flex;
     justify-content: center;
     min-height: 50px;
     width: 100%;
     max-width: 100%;
     background: $color-white;
     font-size: 18px;
     line-height: 30px;
     padding-bottom: 22px;
     flex-direction: column;

     a {
       color: $color-black;
       display: inline-block;
       margin-left: 3px;
       font-family: $font-global-medium, sans-serif;
       transition: opacity .3s ease;

       &:hover, &:focus {
         opacity: .6;
       }
     }

     ul {
       list-style-type: lower-latin;
       padding-left: 42px;
     }

     ol {
       list-style-type: decimal;
       padding-left: 42px;

       span {
         font-family: $font-global-medium, sans-serif;
         font-style: italic;
       }

       li {
         /*margin-bottom: 20px;*/

         &:last-child {
           margin-bottom: 0;
         }
       }
     }
   }
 }

  @media (max-width: 767px) {
    .faq {
      &-list {
        margin: 0 15px;
      }
    }
  }
  @media (max-width: 480px) {
    .questions-mobile {
      padding-top: 39px;
      padding-bottom: 44px;

      &__title {
        margin-bottom: 32px;
      }
    }

    .faq {
      &-list {
        margin: 0;
      }

      &-item {
        padding-left: 16px;
        margin-bottom: 7px;
      }

      &__answer {
        font-size: 12px;
        line-height: 17px;
      }

      &__question {
        font-size: 13px;
        line-height: 16px;
        min-height: 47px;
      }
    }
  }
</style>
