<template>
  <div
    id="bundles-mobile"
    class="bundles-mobile"
  >
    <div class="container">
      <h2 class="bundles-mobile__title">
        Bundles
      </h2>
      <div class="bundles-mobile__desc description">
        <span>Get the best value and price by bundling our solutions together.</span>
        <span>This is limited time offer pricing for churches & ministries with less than 1,000 attendees.</span>
        <span>Lock in a low price by signing up today. This pricing will not change for you ever. If you have </span>
        <span>1,000+ attendees or donors, please check our <a
          href="#"
          @click.prevent="showModalGetStarted = true"
        >Pricing page.</a></span>
      </div>
      <bundles-mobile-elements />
    </div>
  </div>
</template>

<script>
import bundlesMobileElements from '@/components/bundlesMobileElements';

export default {
  name: 'BundlesMobile',
  components: {
    bundlesMobileElements,
  },
  data() {
    return {
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .bundles-mobile {
    padding-top: 64px;
    padding-bottom: 72px;
    background-color: $color-white;
    position: relative;
    overflow: hidden;

    &__desc {
      margin-top: 9px;

      span {
        display: block;

        &:last-child {
          display: inline-block;
          margin-left: 153px;
        }
      }

      a {
        text-decoration: none;
        font-family: $font-global-bold, sans-serif;
        color: $color-royal-blue;
        margin-left: 4px;
        transition: opacity .3s ease;

        &:hover, &:focus {
          opacity: .6;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
