<template>
  <div class="feedback-mobile">
    <div class="container">
      <h2 class="feedback-mobile__title">
        Feedback
      </h2>
      <div class="feedback-mobile__description description">
        Positive feedback about solutions that our team created.
      </div>
      <div class="feedback-mobile-content">
        <div class="feedback-mobile-row">
          <div class="feedback-mobile-row-item feedback-mobile-row-item_transparent">
            <div class="feedback-mobile-row-item__text">
              <span>… A whole new level, a new</span>
              <span>horizon. Thank you for helping</span>
              <span>Life Without Limbs reach millions</span>
              <span>of people around the world.</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Nick Vujicic
            </div>
          </div>
          <div class="feedback-mobile-row-item feedback-mobile-row-item_purple">
            <div class="feedback-mobile-row-item__text">
              <span>Amazing, Phenomenal app!!!</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Tanja Brann
            </div>
          </div>
          <div class="feedback-mobile-row-item feedback-mobile-row-item_right">
            <div class="feedback-mobile-row-item__text">
              <span>…it is pure awesomeness!</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Gunrer Johnson
            </div>
          </div>
        </div>
        <div class="feedback-mobile-row">
          <div class="feedback-mobile-row-item feedback-mobile-row-item_green">
            <div class="feedback-mobile-row-item__text">
              <span>The app is sublime.</span>
              <span>Really, really impressive!</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Bryan Roberts
            </div>
          </div>
          <div class="feedback-mobile-row-item feedback-mobile-row-item_blue">
            <div class="feedback-mobile-row-item__text">
              <span>ImpactFactors creates amazing</span>
              <span>innovations to help Churches</span>
              <span>and Ministries around the world.</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              Brian Flecker
            </div>
          </div>
        </div>
        <div class="feedback-mobile-row">
          <div class="feedback-mobile-row-item feedback-mobile-row-item_left">
            <div class="feedback-mobile-row-item__text">
              <span>It has many features that i</span>
              <span>dreamed it would!</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Gunrer Johnson
            </div>
          </div>
          <div class="feedback-mobile-row-item feedback-mobile-row-item_orange">
            <div class="feedback-mobile-row-item__text">
              <span>This is the</span>
              <span>Ferrari of all</span>
              <span>apps!</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Lawrence Swicegood
            </div>
          </div>
          <div class="feedback-mobile-row-item feedback-mobile-row-item_yellow">
            <div class="feedback-mobile-row-item__text">
              <span>Extremely</span>
              <span>impressed</span>
            </div>
            <div class="feedback-mobile-row-item__author">
              – Tom Lane
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackMobile',
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null,
        },
      );
      wow.init();
    });
  },
  methods: {},
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .feedback-mobile {
    padding-top: 80px;
    padding-bottom: 41px;
    background: $color-black-squeeze;
    position: relative;
    overflow: hidden;

    &__description {
      margin-top: 9px;
    }

    &-content {
      margin-top: 53px;
      position: relative;
    }

    &-row {
      display: flex;

      &-item {
        display: flex;
        height: 158px;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex-shrink: 0;

        &__text {
          line-height: 34px;
          position: relative;

          span {
            display: block;
          }
        }

        &__author {
          font-size: 14px;
          line-height: 18px;
          position: relative;
        }

        &_transparent {
          /*left: -17px;*/
          left: 0;
          top: 11px;

          .feedback-mobile-row-item__author {
            margin-left: auto;
            top: 19px;
          }
        }

        &_green {
          background: rgba(68, 164, 95, .2);
          border-radius: 98px 0 98px 0;
          max-width: 451px;
          width: 100%;
          top: 45px;
          /*left: -10px;*/
          left: 0;
          height: 165px;

          .feedback-mobile-row-item__text {
            margin-left: 67px;
            top: -8px;
          }

          .feedback-mobile-row-item__author {
            text-align: center;
            left: 45px;
            top: 7px;
          }
        }

        &_purple {
          border-radius: 0 98px 98px 0;
          background: rgba(113, 111, 206, .2);
          left: 46px;
          max-width: 360px;
          width: 100%;
          top: 97px;

          .feedback-mobile-row-item__text {
            margin-left: 32px;
            top: -3px;
          }

          .feedback-mobile-row-item__author {
            text-align: right;
            top: 12px;
            left: -43px;
          }
        }

        &_right {
          max-width: 305px;
          width: 100%;
          min-height: 175px;
          border-top: 2px solid $color-san-juan;
          border-left: 2px solid $color-san-juan;
          border-radius: 98px 0 0 0;
          right: 0;
          /*left: 122px;*/
          left: 108px;
          top: 124px;
          padding-top: 0;

          .feedback-mobile-row-item__text {
            top: -22px;
            left: 49px;
          }

          .feedback-mobile-row-item__author {
            text-align: right;
            top: -7px;
            left: 8px;
          }
        }

        &_left {
          max-width: 323px;
          width: 100%;
          min-height: 158px;
          border-right: 2px solid $color-san-juan;
          border-bottom: 2px solid $color-san-juan;
          border-radius: 0 0 98px 0;
          /*left: -17px;*/
          left: 0;
          top: -43px;
          height: 184px;
          padding-top: 34px;

          .feedback-mobile-row-item__text {
            top: -22px;
          }

          .feedback-mobile-row-item__author {
            text-align: right;
            left: -58px;
            top: -7px;
          }
        }

        &_orange {
          background: $color-mona-lisa;
          max-width: 234px;
          width: 100%;
          min-height: 261px;
          border-radius: 99px 0 99px 0;
          left: 114px;
          top: -85px;
          z-index: 5;

          .feedback-mobile-row-item__text, .feedback-mobile-row-item__author {
            color: $color-white;
          }

          .feedback-mobile-row-item__text {
            font-family: $font-third-medium, sans-serif;
            font-size: 29px;
            line-height: 37px;
            left: 42px;
            top: -5px;
            letter-spacing: -.7px
          }

          .feedback-mobile-row-item__author {
            text-align: center;
            top: 3px;
            left: 10px;
          }
        }

        &_blue {
          max-width: 748px;
          width: 100%;
          min-height: 239px;
          background: rgba(71, 196, 224, .2);
          border-radius: 0 119px 0 119px;
          /*left: -76px;*/
          left: -87px;
          top: 103px;

          .feedback-mobile-row-item__text {
            margin-left: 340px;
            top: -21px;
          }

          .feedback-mobile-row-item__author {
            margin-left: 343px;
            top: -6px;
          }
        }

        &_yellow {
          max-width: 235px;
          width: 100%;
          background: rgba(252, 235, 59, .7);
          border-radius: 79px 0 79px 79px;
          height: 158px;
          position: absolute;
          bottom: 40px;
          right: 55px;

          .feedback-mobile-row-item__text {
            text-align: center;
            top: -2px;
            left: 3px;

            span {
              display: block;
            }
          }

          .feedback-mobile-row-item__author {
            text-align: center;
            top: 5px;
            left: 25px;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
