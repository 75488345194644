<template>
  <div class="bible">
    <div class="container">
      <div class="bible-content">
        <h2 class="bible__title">
          Bible
        </h2>
        <div class="bible__description description">
          Help instill a love for God’s Word in the most relevant, effective way for all
          generations. Provides not just the complete Bible in a variety of translations,
          but now the newest and most efficient search technology available!
        </div>
        <div class="bible-info">
          <a
            href=""
            class="btn bible__btn"
          >Learn more</a>
          <button class="btn bible__btn">
            Learn about Bible Experience for Web
          </button>
        </div>
        <div class="bible-list">
          <div
            v-for="(item) in imageBible"
            :key="item.id"
            class="bible__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bible',
  data() {
    return {
      imageBible: [
        {
          id: 0,
          img: 'bible__img-1',
        },
        {
          id: 1,
          img: 'bible__img-2',
        },
        {
          id: 2,
          img: 'bible__img-3',
        },
        {
          id: 3,
          img: 'bible__img-4',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .bible {
    /*padding-bottom: 76px;*/
    /*padding-top: 80px;*/
    padding-bottom: 51px;
    padding-top: 56px;
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/bible__bg.png) no-repeat center center;
    background-size: 100%;

    &-content {
      max-width: 649px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
    }

    &-list {
      display: flex;
      margin: 87px -75px 30px;
      padding-left: 70px;
    }

    &__img {
      margin: 0 -9px;
    }

    &__title {
      font-size: 31px;
      line-height: 40px;
      color: $color-white;
      text-align: left;
    }

    &__description {
      color: $color-white;
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
      text-align: left;
      max-width: 370px;
      width: 100%;
    }

    &-info {
      display: flex;
      margin-top: 17px;
      visibility: hidden;
      opacity: 0;

      .bible__btn + .bible__btn {
        margin-left: 16px;
      }
    }

    &__btn {
      color: $color-white;
      border-color: $color-white;
      text-decoration: none;
      border-radius: 5px;
      width: auto;
      padding: 0 16px;
      transition: all .15s ease-in;

      &:hover {
        opacity: .6;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
