<template>
  <div class="modules">
    <div class="container">
      <h2 class="modules__title">
        Modules & Features
      </h2>
      <div class="modules__description description">
        <span>More modules and features = more utility, value and excitement for users...</span>
        <span><span class="modules__description_fix">...and more ways for you to</span>  <span class="modules__description_selected"></span></span>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from 'typed.js';

export default {
  name: 'Modules',
  mounted() {
    /* eslint-disable */
    let typed = new Typed('.modules__description_selected', {
      strings: ['Inspire', 'Inform', 'Communicate', 'Engage', 'Impact', 'Fundraise', 'Educate', 'Convert'],
      typeSpeed: 40,
      backSpeed: 40,
      showCursor: false,
      // startDelay: 200,
      // fadeOut: 300,
      // backDelay: 90,
      loop: true
    })
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .modules {
    padding-bottom: 63px;
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    background: $color-white;
    background-size: cover;

    &__title {
      color: $color-shark;
      font-size: 45px;
      line-height: 56px;
    }

    &__description {
      color: $color-shark;
      margin-top: 17px;

      span {
        display: block;
      }

      &_selected {
        display: inline-block !important;
        background: $color-black;
        color: $color-white;
        font-family: $font-global-bold, sans-serif;
        padding: 0 10px;
        /*width: 110px;*/
        position: absolute;
      }
      &_fix {
        /*width: 270px;*/
        display: inline-block !important;
        margin-right: 5px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
