<template>
  <div class="sermons">
    <div class="video">
      <template v-if="pcMode">
        <video
          loop
          muted
          autoplay
          playsinline
          class="sermons__video"
        >
          <source
            src="../assets/video/sermon.mp4"
            type="video/mp4"
          >
        </video>
      </template>
      <template v-else>
        <video
          loop
          muted
          autoplay
          playsinline
          class="sermons__video"
        >
          <source
            src="../assets/video/sermon-mob.mp4"
            type="video/mp4"
          >
        </video>
      </template>
    </div>
    <div class="container">
      <div class="sermons-content">
        <h2 class="sermons__title">
          Sermons & Sermon Series
        </h2>
        <div class="sermons__description description">
          Whether they are home or traveling, they’ll appreciate your sermons at their fingertips.
          Listen, watch or read complete sermons or just a segment.
          Your message reaches everyone, everywhere!
        </div>
        <div class="sermons-info">
          <a
            href=""
            class="btn sermons__btn"
          >Read more</a>
          <button class="btn sermons__btn">
            Explore Media Platform
          </button>
        </div>
        <div class="sermons-list">
          <div
            v-for="(item) in imageSermons"
            :key="item.id"
            class="sermons__img"
          >
            <img
              :src="require('../assets/images/' + item.img + '.png')"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileDetect from 'mobile-detect';

export default {
  name: 'Sermons',
  data() {
    return {
      pcMode: true,
      imageSermons: [
        {
          id: 0,
          img: 'sermons__img-1',
        },
        {
          id: 1,
          img: 'sermons__img-2',
        },
        {
          id: 2,
          img: 'sermons__img-3',
        },
      ],
    };
  },
  mounted() {
    const md = new MobileDetect(window.navigator.userAgent);
    const mobile = md.mobile();
    const tablet = md.tablet();
    if (mobile !== null || tablet !== null) {
      console.log('ADAPTIVE');
      this.pcMode = false;
    } else {
      console.log('DESCTOP');
    }
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';

  .sermons {
    padding-bottom: 4px;
    padding-top: 72px;
    /*padding-bottom: 4px;*/
    /*padding-top: 172px;*/
    position: relative;
    overflow: hidden;
    //background: $color-white url(../assets/images/sermons__bg.png) no-repeat center center;
    background: $color-white;
    background-size: cover;

    .video {
      /*position: absolute;*/
      /*!*padding-bottom: 46.25%;*!*/
      /*overflow: hidden;*/
      /*height: 790px;*/
      /*top: 0;*/
      /*left: 0;*/
      /*width: 100%;*/
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
    }

    &__video {
      /*position: absolute;*/
      /*width: 100%;*/
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      min-width: 50%;
      min-height: 50%;
    }

    &-content {
      max-width: 542px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      position: relative;
      padding-left: 22px;
    }

    &-list {
      display: flex;
      margin: 118px -44px 30px;
      padding-left: 30px;
    }

    &__img {
      margin: 0 -5px;
    }

    &__title {
      font-size: 31px;
      line-height: 40px;
      color: $color-white;
      text-align: left;
    }

    &__description {
      color: $color-white;
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
      text-align: left;
      max-width: 355px;
      width: 100%;
    }

    &-info {
      display: flex;
      margin-top: 17px;
      visibility: hidden;
      opacity: 0;

      .sermons__btn + .sermons__btn {
        margin-left: 16px;
      }
    }

    &__btn {
      color: $color-curious-blue;
      border-color: $color-picton-blue;
      text-decoration: none;
      border-radius: 5px;
      width: auto;
      padding: 0 16px;
      transition: all .15s ease-in;

      &:hover {
        border-color: $color-white;
        background: $color-white;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
