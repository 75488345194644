<template>
  <div class="best-practices-mobile">
    <div class="container">
      <h2 class="best-practices-mobile__title">
        Best practices all across the board
      </h2>
      <div class="best-practices-mobile__desc description">
        Ideas & Solutions you won't find anywhere else.
      </div>
      <best-practice-mobile-elements />
    </div>
  </div>
</template>

<script>
import bestPracticeMobileElements from '@/components/bestPracticesMobileElements';

export default {
  name: 'BestPracticesMobile',
  components: {
    bestPracticeMobileElements,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .best-practices-mobile {
    padding-top: 80px;
    padding-bottom: 65px;
    background-color: $color-gallery;
    position: relative;
    overflow: hidden;

    &__desc {
      margin-top: 9px;
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
