<template>
  <div class="next-generation-general">
    <swiper
      ref="swiperGeneration"
      :options="swiperOptionGeneration"
    >
      <swiper-slide
        v-for="(item) in nextGenerationSlide"
        :key="item.id"
      >
        <component :is="item.component"></component>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import nextGenerationMobile from '@/components/nextGenerationMobile';
import nextGenerationMobileSecond from '@/components/nextGenerationMobileSecond';
import nextGenerationMobileThird from '@/components/nextGenerationMobileThird';

export default {
  name: 'NextGenerationGeneral',
  components: {
    nextGenerationMobile,
    nextGenerationMobileSecond,
    nextGenerationMobileThird,
  },
  data() {
    return {
      nextGenerationSlide: [
        {
          id: 0,
          component: 'nextGenerationMobile',
        },
        {
          id: 1,
          component: 'nextGenerationMobileSecond',
        },
        {
          id: 2,
          component: 'nextGenerationMobileThird',
        },
      ],
      swiperOptionGeneration: {
        slidesPerView: 1,
        autoplay: true,
        // delay: 5000,
        effect: 'fade',
        speed: 1000,
        // on: {
        //   slideChange: this.slideChangeSwiper
        // }
      },
    };
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .next-generation-general {
    margin-top: -127px;
  }
</style>
