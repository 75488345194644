<template>
  <div class="live-streaming">
    <div class="container">
      <div class="live-streaming-content">
        <h2 class="live-streaming__title">
          Live Streaming or Simulated TV
        </h2>
        <div class="live-streaming__description description">
          <span>Amazing! Users can watch your live sermons, concerts, and special</span>
          <span>events from anywhere on their mobile phones!</span>
          <span>Native user experience.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveStreaming',
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .live-streaming {
    /*padding-bottom: 388px;*/
    /*padding-top: 475px;*/
    padding-bottom: 351px;
    padding-top: 439px;
    position: relative;
    overflow: hidden;
    background: $color-white url(../assets/images/live-streaming__bg.png) no-repeat 50% 20px;
    background-size: contain;

    &-content {
      position: absolute;
      left: 48.5%;
      top: 112px;
      transform: translateX(-50%);
    }

    &__title {
      color: $color-shark;
      font-size: 31px;
      line-height: 40px;
    }

    &__description {
      color: $color-shark;
      margin-top: 17px;
      font-size: 16px;
      line-height: 24px;

      span {
        display: block;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
